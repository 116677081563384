import { BaseLocales } from './i18nLingui';

export interface LocalConfig {
  home: string;
  guidedMeditations: string;
  meditationTeachers: string;
  meditationCourses: string;
  meditationTopics: string;
  howToMeditate: string;
  resetPassword: string;
  freeTrial: string;
  play: string;
  deviceLang: string;
  contentLangs: string;
}

interface LocalConfigs {
  [keys: string]: LocalConfig;
}

export const localConfigs: LocalConfigs = {
  [BaseLocales.English]: {
    home: '',
    guidedMeditations: 'guided-meditations',
    meditationTeachers: '',
    meditationCourses: 'meditation-courses',
    meditationTopics: 'meditation-topics',
    howToMeditate: 'how-to-meditate',
    resetPassword: 'reset-password',
    freeTrial: 'subscribe/free-trial',
    play: 'play',
    deviceLang: 'en',
    contentLangs: 'en'
  },
  [BaseLocales.BrazilianPortuguese]: {
    home: 'br',
    guidedMeditations: 'meditacao-guiada',
    meditationTeachers: 'professores',
    meditationCourses: 'cursos',
    meditationTopics: 'meditacao-topicos',
    howToMeditate: 'como-meditar',
    resetPassword: 'redefina-senha',
    freeTrial: 'assine/teste-gratuito',
    play: 'tocar',
    deviceLang: 'pt-BR',
    contentLangs: 'pt-BR,pt'
  },
  [BaseLocales.French]: {
    home: 'fr',
    guidedMeditations: 'meditation-guidee',
    meditationTeachers: 'professeur',
    meditationCourses: 'cours-de-meditation',
    meditationTopics: 'meditation-themes',
    howToMeditate: 'comment-mediter',
    resetPassword: 'reinitialiser',
    freeTrial: 'abonnez-vous/essai-gratuit',
    play: 'play',
    deviceLang: 'fr',
    contentLangs: 'fr'
  },
  [BaseLocales.Russian]: {
    home: 'ru',
    guidedMeditations: 'gid-meditacii',
    meditationTeachers: 'meditacija-uchitelja',
    meditationCourses: 'kursy-meditacii',
    meditationTopics: 'temy-meditatsii',
    howToMeditate: '',
    resetPassword: 'sbros-parolja',
    freeTrial: '',
    play: 'play',
    deviceLang: 'ru',
    contentLangs: 'ru'
  },
  [BaseLocales.German]: {
    home: 'de',
    guidedMeditations: 'gefuehrte-meditation',
    meditationTeachers: 'meditationslehrer',
    meditationCourses: 'meditationskurse',
    meditationTopics: 'meditation-themen',
    howToMeditate: 'meditation-lernen',
    resetPassword: '',
    freeTrial: 'abonnieren/testphase',
    play: 'abspielen',
    deviceLang: 'de',
    contentLangs: 'de'
  },
  [BaseLocales.Spanish]: {
    home: 'es',
    guidedMeditations: 'meditacion-guiada',
    meditationTeachers: 'profesores',
    meditationCourses: 'cursos-de-meditacion',
    meditationTopics: 'meditacion-temas',
    howToMeditate: 'como-meditar-guia',
    resetPassword: 'restablecer-contrasena',
    freeTrial: 'suscribir/prueba-gratis',
    play: 'tocar',
    deviceLang: 'es',
    contentLangs: 'es'
  },
  [BaseLocales.Dutch]: {
    home: 'nl',
    guidedMeditations: 'geleide-meditatie',
    meditationTeachers: 'docent',
    meditationCourses: 'meditatiecursus',
    meditationTopics: 'meditatie-onderwerpen',
    howToMeditate: '',
    resetPassword: 'opnieuw-instellen',
    freeTrial: 'abonneren/proefabonnement',
    play: 'play',
    deviceLang: 'nl',
    contentLangs: 'nl'
  },
  [BaseLocales.Italian]: {
    home: 'it',
    guidedMeditations: 'meditazione-guidata',
    meditationTeachers: 'insegnante',
    meditationCourses: 'corso-di-meditazione',
    meditationTopics: 'argomenti-di-meditazione',
    howToMeditate: '',
    resetPassword: 'resetta',
    freeTrial: '',
    play: 'play',
    deviceLang: 'it',
    contentLangs: 'it'
  }
};
