import { extendTheme } from '@chakra-ui/react';
import Button from './components/Button';
import Checkbox from './components/Checkbox';
import CloseButton from './components/CloseButton';
import Heading from './components/Heading';
import Modal from './components/Modal';
import Radio from './components/Radio';
import Text from './components/Text';
import colors from './foundations/colors';
import fonts from './foundations/fonts';

const theme = extendTheme({
  colors,
  fonts,
  components: {
    CloseButton,
    Button,
    Heading,
    Text,
    Radio,
    Checkbox,
    Modal
  }
});

export default theme;
