export interface UserCreatedEventDetailsSchema {
  detail: {
    userRegisteredRoute: string; // Route from where user registered.
  };
}

export const getUserCreatedEventDetails = () => {
  const pathName = window.location.pathname;

  const details = {
    detail: {
      userRegisteredRoute: pathName
    }
  };

  return details;
};

export const convertEventIntoUserRegisteredEventDetails = (event: Event) => {
  const eventDetails = (event as CustomEvent) as UserCreatedEventDetailsSchema;

  return eventDetails;
};
