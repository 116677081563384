import * as Branch from 'api/branch';
import { DeepLinkData } from 'branch-sdk';
import { ShareItemType } from 'components_2/base/ShareMenu/types';
import React, {
  lazy,
  Suspense,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { useFirebaseAuth } from './useFirebaseAuth';

interface GeneratedBranchLinkDataSchema extends DeepLinkData {
  data: DeepLinkData['data'] & {
    $share_link_id: string;
    $share_created_date: string;
    $sharer_user_id?: string;
  };
}

interface State {
  isOpen: boolean;
  deepLink?: string;
  webLink?: string;
  branchLink?: string;
  title?: string;
  message?: string;
  ogImageUrl?: string;
  ogDescription?: string;
  fallbackUrl?: string; // branch use this url when user don't have InsightTimer app on the device.
  isWebOnly?: boolean;
  shareItemId?: string;
  shareItemType?: ShareItemType;
  branchLinkData?: GeneratedBranchLinkDataSchema;
  embedContentType?: 'TRACK' | 'PLAYLIST' | 'PUBLISHER';
  embedContentId?: string;
}

type Action =
  | {
      type: 'SHOW_POPUP';
      deepLink?: string;
      webLink: string;
      title: string;
      message?: string;
      ogImageUrl?: string;
      ogDescription?: string;
      fallbackUrl?: string;
      isWebOnly?: boolean;
      shareItemId?: string;
      shareItemType?: ShareItemType;
      embedContentType?: 'TRACK' | 'PLAYLIST' | 'PUBLISHER';
      embedContentId?: string;
    }
  | {
      type: 'HIDE_POPUP';
    };

type InternalAction =
  | { type: 'SET_BRANCH_LINK'; value: string }
  | {
      type: 'SET_GENERATED_BRANCH_LINK_DATA';
      value: GeneratedBranchLinkDataSchema;
    };

type ReducerAction = Action | InternalAction;

const initialState: State = {
  isOpen: false
};

const ShareModal = lazy(() => import('components/ShareModal'));

const reducer = (state: State, action: ReducerAction): State => {
  switch (action.type) {
    case 'SHOW_POPUP':
      return {
        ...state,
        isOpen: true,
        deepLink: action.deepLink,
        webLink: action.webLink,
        title: action.title,
        message: action.message,
        ogImageUrl: action.ogImageUrl,
        ogDescription: action.ogDescription,
        fallbackUrl: action.fallbackUrl,
        isWebOnly: action.isWebOnly,
        shareItemId: action.shareItemId,
        shareItemType: action.shareItemType,
        embedContentType: action.embedContentType,
        embedContentId: action.embedContentId
      };
    case 'HIDE_POPUP':
      return {
        ...state,
        isOpen: false,
        deepLink: undefined,
        webLink: undefined,
        title: undefined,
        message: undefined,
        ogImageUrl: undefined,
        ogDescription: undefined,
        fallbackUrl: undefined,
        branchLink: undefined,
        shareItemId: undefined,
        shareItemType: undefined,
        branchLinkData: undefined,
        embedContentType: undefined
      };
    case 'SET_BRANCH_LINK':
      return { ...state, branchLink: action.value };
    case 'SET_GENERATED_BRANCH_LINK_DATA':
      return { ...state, branchLinkData: action.value };
    default:
      return state;
  }
};

export const SharePopupContext = React.createContext<
  { dispatch: React.Dispatch<Action>; state: State } | undefined
>(undefined);

export const SharePopupProvider: React.FC = ({ children }) => {
  const { user, userPseudoId } = useFirebaseAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isOpen,
    webLink,
    deepLink,
    branchLink,
    title,
    message,
    ogImageUrl,
    ogDescription,
    fallbackUrl,
    isWebOnly,
    shareItemId,
    shareItemType,
    branchLinkData
  } = state;

  const [loadShareModal, setLoadShareModal] = useState(false);

  useEffect(() => {
    if (isOpen) setLoadShareModal(true);
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen || !webLink || !deepLink) {
      return;
    }

    const branchData: Branch.BranchSharedUrlDataSchema = {
      webLink,
      deepLink,
      campaign: 'web-share', // utm_campaign
      sharerUserId: user?.uid,
      sharerUserPseudoId: userPseudoId,
      isWebOnly,
      ...(fallbackUrl ? { fallbackUrl } : {}),
      linkType: 'content_share',
      additionalBranchData: {
        $og_title: title,
        $og_description: ogDescription,
        $og_image_url: ogImageUrl
      }
    };

    Branch.getBranchIOShareUrl({
      data: branchData,
      cb: ({ error, link, branchLinkDetails }) => {
        if (error) console.warn(error);

        dispatch({
          type: 'SET_BRANCH_LINK',
          value: error || !link ? webLink : link
        });

        dispatch({
          type: 'SET_GENERATED_BRANCH_LINK_DATA',
          value: branchLinkDetails as GeneratedBranchLinkDataSchema
        });
      }
    });
  }, [
    deepLink,
    webLink,
    isOpen,
    user,
    ogImageUrl,
    ogDescription,
    isWebOnly,
    title,
    fallbackUrl,
    userPseudoId
  ]);

  return (
    <SharePopupContext.Provider value={{ dispatch, state }}>
      <Suspense fallback={<></>}>
        {loadShareModal && (
          <ShareModal
            open={isOpen}
            onClose={() => dispatch({ type: 'HIDE_POPUP' })}
            title={title || ''}
            message={message || ''}
            url={branchLink || webLink || ''}
            shareItemId={shareItemId}
            shareItemType={shareItemType}
            shareUserId={user?.uid}
            shareLinkId={branchLinkData?.data?.$share_link_id}
            shareCreatedDate={branchLinkData?.data?.$share_created_date}
          />
        )}
      </Suspense>
      {children}
    </SharePopupContext.Provider>
  );
};

export function useSharePopup() {
  const context = useContext(SharePopupContext);
  if (!context) {
    throw new Error('useSharePopup needs to be used within SharePopupProvider');
  }

  return context;
}
