import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
import { checkIsPrerender } from 'hooks/usePrerender';
import { isDevelopment } from 'lib/environment';
import React, { createContext, useContext, useState } from 'react';

interface Context {
  agoraClient: IAgoraRTCClient | null;
  loadAgora?: () => Promise<IAgoraRTCClient | null>;
}

const LiveEventPreviewContext = createContext<Context>({
  agoraClient: null
});

export const LiveEventPreviewProvider: React.FC = ({ children }) => {
  const [agoraClient, setAgoraClient] = useState<IAgoraRTCClient | null>(null);

  const loadAgora = async () => {
    if (checkIsPrerender()) {
      return null;
    }

    if (agoraClient) {
      return agoraClient;
    }

    try {
      const { default: AgoraRTC } = await import('agora-rtc-sdk-ng');

      if (!isDevelopment) {
        AgoraRTC.setLogLevel(4);
      }

      const agoraRtc = AgoraRTC.createClient({ mode: 'live', codec: 'h264' });

      agoraRtc.setClientRole('audience');
      setAgoraClient(agoraRtc);

      return agoraRtc;
    } catch (e) {
      return null;
    }
  };

  return (
    <LiveEventPreviewContext.Provider
      value={{
        agoraClient,
        loadAgora
      }}
    >
      {children}
    </LiveEventPreviewContext.Provider>
  );
};

export const useLiveEventPreview = () => {
  const context = useContext(LiveEventPreviewContext);
  return context;
};
