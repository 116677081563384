import { ComponentStyleConfig } from '@chakra-ui/react';

const Checkbox: ComponentStyleConfig = {
  variants: {
    'retreats-filter': {
      control: {
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: 'neutral.400',
        _focus: {
          outline: 'none',
          boxShadow: 'none'
        },
        _checked: {
          background: 'neutral.700',
          borderColor: 'neutral.700',
          _hover: { background: 'neutral.700', borderColor: 'neutral.700' }
        }
      },
      label: {
        width: '100%'
      }
    }
  }
};

export default Checkbox;
