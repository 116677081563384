import { getBaseLocale } from 'locales/helpers';
import { Locales } from 'locales/i18nLingui';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { IntlProvider } from 'react-intl';
// local data of different translation
import messages_en from 'Translations/en.json';
// import messages_hi from 'Translations/hi.json';
// import topics_br from 'Translations/topics.br.json';
// import topics_de from 'Translations/topics.de.json';
import topics_en from 'Translations/topics.en.json';
// import topics_es from 'Translations/topics.es.json';
// import topics_fr from 'Translations/topics.fr.json';
// import topics_it from 'Translations/topics.it.json';
// import topics_nl from 'Translations/topics.nl.json';
// import topics_ru from 'Translations/topics.ru.json';

type MessagesType = {
  [key: string]: {
    [key: string]: string;
  };
};

const topicsMessages: MessagesType = {
  en: {
    ...messages_en,
    ...topics_en,
    'topics.music.name': 'Music'
  }
  // hi: messages_hi,
  // br: topics_br,
  // de: topics_de,
  // es: topics_es,
  // fr: topics_fr,
  // it: topics_it,
  // nl: topics_nl,
  // ru: topics_ru
};

/* 
check given string is valid format locale code
example: en, en-us, en-uk
*/
function isValidLocaleCode(localeCode: string) {
  // The pattern allows for letters (a-z) and optional hyphens, followed by more letters (a-z)
  const pattern = /^[a-zA-Z]{2}(-[a-zA-Z]{2})?$/i;

  // Test if the localeCode matches the pattern
  return pattern.test(localeCode);
}

const getLocaleByUrl = () => {
  const { pathname } = window.location;
  const directories = pathname.split('/');

  if (directories.length >= 2) {
    const localeCode = directories[1];
    if (isValidLocaleCode(localeCode)) return localeCode;
  }

  return 'en';
};

interface Context {
  setLocale: Dispatch<SetStateAction<string>>;
}

const I18nContext = createContext<Context | undefined>(undefined);

const I18nWrapper: React.FC = ({ children }) => {
  const [locale, setLocale] = useState<string>('en');
  const [messages, setMessages] = useState<Record<string, string>>(
    topicsMessages.en
  );

  useEffect(() => {
    const loadMsg = async () => {
      const locale = getLocaleByUrl() || 'en';
      const baseLocale =
        locale === 'br' ? locale : getBaseLocale(locale as Locales);

      if (baseLocale.includes('en')) {
        setMessages(topicsMessages.en);
        return;
      }

      const topicMsgs = await import(`Translations/topics.${baseLocale}.json`);

      setMessages(topicMsgs);
      setLocale(baseLocale);
    };

    loadMsg();
  }, [locale]);

  return (
    <I18nContext.Provider
      value={{
        setLocale
      }}
    >
      <IntlProvider locale={locale} messages={messages} defaultLocale="en">
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
};

export const useI18nContext = () => {
  const context = useContext(I18nContext);

  if (!context) {
    throw new Error('useI18nContext needs to be used within I18nWrapper');
  }

  return context;
};

export default I18nWrapper;
