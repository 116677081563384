import {
  LogGAClickEventParams,
  logGAEvent,
  logPageViewEvent,
  setAnalyticsUserProperties
} from 'lib/firebase/ga_analytics';
import GA_EVENTS from 'lib/firebase/ga_events';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router';
import { LooseObject } from 'services/interface';
import { useFirebaseAuth } from './useFirebaseAuth';

const defaultTitle =
  'Insight Timer - #1 Free Meditation App for Sleep, Relax & More';

interface ViewImpressionParams extends LooseObject {
  view_event_name: string;
}

interface PreviousAndCurrentRouteInterface {
  previousRoute: string;
  currentRoute: string;
  currentRouteWithSearchQuery: string;
  previousRouteWithSearchQuery: string;
}

interface Context {
  trackGAEvent: typeof logGAEvent;
  trackGAClickEvent: (eventDetails: LogGAClickEventParams) => void;
  viewImpression: (eventDetails: ViewImpressionParams) => void;
  previousAndCurrentRoute: PreviousAndCurrentRouteInterface;
}

const AnalyticsContext = createContext<Context | null>(null);

const ignoreRoutes = ['/retreats', '/retreat'];

export const AnalyticsProvider: React.FC = ({ children }) => {
  const location = useLocation();
  const [previousAndCurrentRoute, setPreviousAnsCurrentRoute] = useState<
    PreviousAndCurrentRouteInterface
  >({
    previousRoute: location.pathname,
    currentRoute: location.pathname,
    previousRouteWithSearchQuery: `${location.pathname}${location.search ??
      ''}`,
    currentRouteWithSearchQuery: `${location.pathname}${location.search ?? ''}`
  });

  const { user, isSubscriber } = useFirebaseAuth();

  const [documentTitle, setDocumentTitle] = useState<string>(document.title); // Store document title

  useEffect(() => {
    setAnalyticsUserProperties({
      userId: user?.uid,
      isSubscriber
    });
  }, [isSubscriber, user]);

  useEffect(() => {
    const titleElement = document.querySelector('title');

    if (!titleElement) {
      return undefined;
    }

    const documentTitleObserver = new MutationObserver(() => {
      if (location.pathname !== '/') {
        setDocumentTitle(document.title);
      }
    });

    // observe changes happen in title tag
    documentTitleObserver.observe(titleElement, {
      childList: true
    });

    return () => {
      documentTitleObserver.disconnect();
    };
  }, [location.pathname, previousAndCurrentRoute]);

  useEffect(() => {
    setPreviousAnsCurrentRoute(details => {
      const newRouteWithSearch = `${location.pathname}${location.search ?? ''}`;

      if (newRouteWithSearch === details.currentRouteWithSearchQuery) {
        return details;
      }

      return {
        previousRoute: details.currentRoute,
        currentRoute: location.pathname,
        previousRouteWithSearchQuery: details.currentRouteWithSearchQuery,
        currentRouteWithSearchQuery: newRouteWithSearch
      };
    });
  }, [location]);

  // Track for homepage
  useEffect(() => {
    const { previousRoute, currentRoute } = previousAndCurrentRoute;

    if (currentRoute === '/' && location.pathname === '/') {
      logPageViewEvent({
        page_name: currentRoute,
        previous_page_name: previousRoute,
        user_agent: navigator?.userAgent,
        page_title: defaultTitle
      });
    }
  }, [location.pathname, previousAndCurrentRoute]);

  useEffect(() => {
    const { previousRoute, currentRoute } = previousAndCurrentRoute;

    if (
      documentTitle !== defaultTitle &&
      !ignoreRoutes.some(route => currentRoute.startsWith(route))
    ) {
      logPageViewEvent({
        page_name: currentRoute,
        previous_page_name: previousRoute,
        user_agent: navigator?.userAgent,
        page_title: documentTitle
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTitle]);

  const trackGAClickEvent = useCallback(
    (eventDetails: LogGAClickEventParams): void => {
      logGAEvent({
        event_name: 'click',
        page_name: previousAndCurrentRoute.currentRoute,
        previous_page_name: previousAndCurrentRoute.previousRoute,
        user_agent: navigator?.userAgent,
        ...eventDetails
      });
    },
    [previousAndCurrentRoute]
  );

  const viewImpression = useCallback(
    (eventDetails: ViewImpressionParams) => {
      logGAEvent({
        event_name: GA_EVENTS.VIEW,
        page_name: previousAndCurrentRoute.currentRoute,
        previous_page_name: previousAndCurrentRoute.previousRoute,
        ...eventDetails
      });
    },
    [previousAndCurrentRoute]
  );

  return (
    <AnalyticsContext.Provider
      value={{
        trackGAEvent: logGAEvent,
        trackGAClickEvent,
        viewImpression,
        previousAndCurrentRoute
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be use inside AnalyticsProvider');
  }
  return context;
};
