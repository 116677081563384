import { getPerformance } from 'firebase/performance';
import { checkIsPrerender } from 'hooks/usePrerender';
import firebase from '.';

const initializePerformanceMonitoring = () => {
  if (checkIsPrerender()) {
    return null;
  }

  const performanceMonitoring = getPerformance(firebase);

  return performanceMonitoring;
};

export default initializePerformanceMonitoring;
