import { browserName } from 'react-device-detect';

const isPrerenderCrawler = () => {
  const userAgent = navigator?.userAgent ?? browserName ?? '';

  return userAgent.includes(
    'Prerender (+https://github.com/prerender/prerender)'
  );
};

export default isPrerenderCrawler;
