import { useBreakpointValue } from '@chakra-ui/react';

const useBreakpoint = () => {
  const isSmScreen = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false
  });

  const isMdScreen = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    lg: false
  });

  const isLgScreen = useBreakpointValue({
    base: false,
    sm: false,
    md: false,
    lg: true
  });

  return { isSmScreen, isMdScreen, isLgScreen };
};

export default useBreakpoint;
