const routeAlias = {
  homePage: ['/en-au', '/en-ca', '/en-gb', '/en-in']
};

export const homePageEnglishVariantLang = routeAlias.homePage
  .join('|')
  .replace(/\//g, '');

export const getRouteAliases = ({ route }: { route: string }) => {
  const formattedRoute = route?.startsWith('/') ? `${route}` : `/${route}`;

  const variantOfRoutes = routeAlias.homePage.map(
    langRoute => `${langRoute}${formattedRoute}`
  );

  return variantOfRoutes;
};

/**
 * Use to check whether current route is default home route or english variant home route
 * */
export const isDefaultOrEnglishVariantHomeRoute = (route: string) => {
  return route === '/' || routeAlias.homePage.includes(route);
};

export default routeAlias;
