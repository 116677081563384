import { useEffect, useState } from 'react';

export default function usePrerender() {
  const [isPrerender, setIsPrerender] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.includes('Prerender')) {
      setIsPrerender(true);
    } else {
      setIsPrerender(false);
    }
  }, [setIsPrerender]);

  return { isPrerender };
}

export const checkIsPrerender = () => {
  return navigator.userAgent.includes('Prerender');
};
