import SuspenseHandler from 'components_2/loading/SuspenseHandler';
import React, { lazy, useEffect, useRef, useState } from 'react';

const FooterContent = lazy(() => import('./FooterContent'));

interface Props {
  width?: number;
  bottomSpacing?: number;
}

export default function Footer(props: Props) {
  const [loadFooter, setLoadFooter] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const option = { root: null, rootMargin: '100px', threshold: 0.1 };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setLoadFooter(true);
      }
    }, option);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(ref.current);
      }
    };
  }, [ref, option]);

  return (
    <div
      ref={ref}
      style={{ minHeight: '300px', width: '100%', background: 'black' }}
    >
      <SuspenseHandler spinnerColor="white">
        {loadFooter && <FooterContent {...props} />}
      </SuspenseHandler>
    </div>
  );
}
