import React from 'react';
import Helmet from 'react-helmet';

interface Props {
  to: string;
}

const MetaRefreshRedirect = ({ to }: Props) => {
  return (
    <Helmet>
      <meta httpEquiv="refresh" content={`0; url=${to}`} />
    </Helmet>
  );
};

export default MetaRefreshRedirect;
