import noop from 'lodash/noop';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react';

const OnboardingContext = createContext<{
  step: number;
  setStep: (step: number) => void;
}>({
  step: 1,
  setStep: noop
});

export function OnboardingProvider({ children }: PropsWithChildren<{}>) {
  const [step, setStep] = useState(1);

  return (
    <OnboardingContext.Provider
      value={{
        step,
        setStep
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

export const useOnboarding = () => useContext(OnboardingContext);
