import dayjs from 'dayjs';

/**
 * convert seconds into hour, min and seconds
 */
export const secondsToHms = (seconds: number) => {
  const digit = Number(seconds);
  const h = Math.floor(digit / 3600);
  const m = Math.floor((digit % 3600) / 60);
  const s = Math.floor((digit % 3600) % 60);

  const hDisplay = h > 0 ? `${h}h` : '';
  const mDisplay = m > 0 ? `${m}m` : '';
  const sDisplay = s > 0 ? `${s}s` : '';

  if (hDisplay.trim()) {
    return hDisplay;
  }
  if (mDisplay.trim()) {
    return mDisplay;
  }
  if (sDisplay.trim()) {
    return sDisplay;
  }

  return '';
};

/**
 * to format seconds to mm:ss format
 */
export const formatTime = (seconds: number) => {
  let minutes;
  let sec;
  minutes = Math.floor(seconds / 60);
  minutes = minutes >= 10 ? minutes : `0${minutes}`;
  sec = Math.floor(seconds % 60);
  sec = sec >= 10 ? sec : `0${sec}`;
  return `${minutes}:${sec}`;
};

export const secondToMinutes = (seconds: number) => {
  const duration = seconds ?? 0;
  let durationInMinutes = duration / 60;

  durationInMinutes = Number(durationInMinutes.toFixed(2));
  return durationInMinutes;
};

export const getDurationInMinutes = (
  lengthSec: number,
  includePlural?: boolean
) => {
  if (lengthSec) {
    const minutes = Math.floor(lengthSec / 60);

    if (lengthSec > 59) {
      return `${minutes} min${minutes > 1 && includePlural ? 's' : ''}`;
    }

    return `${lengthSec} sec`;
  }
  return '';
};

export const getTimestampAndISOTime = () => {
  const currentDate = dayjs();

  return {
    epoch: currentDate.valueOf(),
    iso_8601_datetime_tz: currentDate.toISOString()
  };
};

export const dateIsThisYear = (dateEpoch: number) => {
  const currentYear = dayjs().year();
  const dateYear = dayjs(dateEpoch).year();
  return currentYear === dateYear;
};

export const getDuration = (valueInSeconds: number) => {
  if (valueInSeconds === 0) return '00:00';

  const pad = (string: string) => {
    return `0${string}`.slice(-2);
  };

  const date = new Date(valueInSeconds * 1000);
  const hh = date.getUTCHours();
  const mm = pad(date.getUTCMinutes().toString());
  const ss = pad(date.getUTCSeconds().toString());

  if (hh) return `${hh}:${mm}:${ss}`;

  return `${mm}:${ss}`;
};
