import Footer from 'components_2/footer';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';

const PageNotFound = () => {
  useEffect(() => {
    const timeoutJob = setTimeout(() => {
      window.prerenderReady = true;
    }, 600);

    return () => {
      clearTimeout(timeoutJob);
    };
  }, []);

  return (
    <div className="flex justify-between flex-col h-screen">
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>

      <div className="h-20" />
      <div
        className="text-center my-32 mx-auto text-black"
        style={{ maxWidth: '300px' }}
      >
        <h1 className="text-lg font-ProxiBold mb-6 leading-tight">
          The page you were looking for doesn&apos;t exist.
        </h1>
        <p className="text-black75 font-ProxiRegular">
          You may have mistyped the address or the page may have moved.
        </p>
      </div>

      <div className="mt-24">
        <Footer />
      </div>
    </div>
  );
};

export default PageNotFound;
