import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';

export const GetAppPopupContext = React.createContext<{
  showGetAppPopup: () => void;
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showGetAppPopup: () => {}
});

const GetAppModal = lazy(() => import('components/GetAppModal'));

export const GetAppPopupProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const showGetAppPopup = useCallback(() => setOpen(true), []);
  const hideGetAppPopup = useCallback(() => setOpen(false), []);

  const [loadPopup, setLoadPopup] = useState(false);

  useEffect(() => {
    if (open) setLoadPopup(true);
  }, [open]);

  return (
    <GetAppPopupContext.Provider
      value={{
        showGetAppPopup
      }}
    >
      <Suspense fallback={<></>}>
        {loadPopup && <GetAppModal open={open} onClose={hideGetAppPopup} />}
      </Suspense>
      {children}
    </GetAppPopupContext.Provider>
  );
};

export const useGetAppPopup = () => useContext(GetAppPopupContext);
