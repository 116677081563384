import PageNotFound from 'components_2/pageNotFound';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DevelopmentRoute = (component: any) => {
  return (
    (window.location.hostname.indexOf('insighttimer.com') === -1 &&
      window.location.hostname.indexOf('www.insighttimer.com') === -1 &&
      component) ||
    PageNotFound
  );
};

export default DevelopmentRoute;
