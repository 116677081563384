import { i18nMark } from '@lingui/core';

/*
 * Countries
 * ---------------------------------------- */
i18nMark('Argentina');
i18nMark('Australia');
i18nMark('Austria');
i18nMark('Belgium');
i18nMark('Brazil');
i18nMark('Chile');
i18nMark('Canada');
i18nMark('Colombia');
i18nMark('Denmark');
i18nMark('Egypt');
i18nMark('Finland');
i18nMark('France');
i18nMark('Germany');
i18nMark('Greece');
i18nMark('Hong Kong');
i18nMark('Indonesia');
i18nMark('Ireland');
i18nMark('Italy');
i18nMark('Japan');
i18nMark('Mexico');
i18nMark('Netherlands');
i18nMark('New Zealand');
i18nMark('Norway');
i18nMark('Poland');
i18nMark('Portugal');
i18nMark('Russia');
i18nMark('Scotland');
i18nMark('Singapore');
i18nMark('South Africa');
i18nMark('Spain');
i18nMark('Sweden');
i18nMark('Switzerland');
i18nMark('Thailand');
i18nMark('Turkey');
i18nMark('United Arab Emirates');
i18nMark('United Kingdom');
i18nMark('United States');

/*
 * Cities
 * ---------------------------------------- */
i18nMark('Buenos Aires');
i18nMark('Melbourne');
i18nMark('Adelaide');
i18nMark('Sydney');
i18nMark('Perth');
i18nMark('Gold Coast');
i18nMark('Brisbane');
i18nMark('Byron Bay');
i18nMark('Vienna');
i18nMark('Brussels');
i18nMark('Gent');
i18nMark('Porto Alegre');
i18nMark('Curitiba');
i18nMark('Rio de Janeiro');
i18nMark('Campinas');
i18nMark('Belo Horizonte');
i18nMark('Brasília');
i18nMark('Sao Paolo');
i18nMark('Santiago');
i18nMark('Toronto');
i18nMark('Ottawa');
i18nMark('Montréal');
i18nMark('Victoria');
i18nMark('Vancouver');
i18nMark('Winnipeg');
i18nMark('Calgary');
i18nMark('Bogotá');
i18nMark('Medellín');
i18nMark('Copenhagen');
i18nMark('Alexandria');
i18nMark('Helsinki');
i18nMark('Bordeaux');
i18nMark('Lyon');
i18nMark('Paris');
i18nMark('Munich');
i18nMark('Stuttgart');
i18nMark('Frankfurt');
i18nMark('Cologne');
i18nMark('Berlin');
i18nMark('Hamburg');
i18nMark('Athens');
i18nMark('Hong Kong');
i18nMark('Bali');
i18nMark('Galway');
i18nMark('Dublin');
i18nMark('Rome');
i18nMark('Tokyo');
i18nMark('Mexico City');
i18nMark('Puerto Vallarta');
i18nMark('Rotterdam');
i18nMark('Arnhem');
i18nMark('The Hague');
i18nMark('Utrecht');
i18nMark('Amsterdam');
i18nMark('Zwolle');
i18nMark('Groningen');
i18nMark('Wellington');
i18nMark('Auckland');
i18nMark('Oslo');
i18nMark('Warsaw');
i18nMark('Lisbon');
i18nMark('Porto');
i18nMark('Moscow');
i18nMark('Saint Petersburg');
i18nMark('Glasgow');
i18nMark('Edinburgh');
i18nMark('Singapore');
i18nMark('Cape Town');
i18nMark('Sevilla');
i18nMark('Valencia');
i18nMark('Madrid');
i18nMark('Barcelona');
i18nMark('Milan');
i18nMark('Stockholm');
i18nMark('Zürich');
i18nMark('Basel');
i18nMark('Bangkok');
i18nMark('Ankara');
i18nMark('Istanbul');
i18nMark('Dubai');
i18nMark('Brighton');
i18nMark('Bristol');
i18nMark('London');
i18nMark('Oxford');
i18nMark('Birmingham');
i18nMark('Nottingham');
i18nMark('Manchester');
i18nMark('Cambridge');
i18nMark('Santa Ana');
i18nMark('Hawaii');
i18nMark('Miami');
i18nMark('Tampa');
i18nMark('Orlando');
i18nMark('Houston');
i18nMark('New Orleans');
i18nMark('Austin');
i18nMark('Dallas');
i18nMark('San Diego');
i18nMark('Phoenix');
i18nMark('Atlanta');
i18nMark('Los Angeles');
i18nMark('Sedona');
i18nMark('Charlotte');
i18nMark('Asheville');
i18nMark('Durham');
i18nMark('Nashville');
i18nMark('Santa Cruz');
i18nMark('San Jose');
i18nMark('San Francisco');
i18nMark('St. Louis');
i18nMark('Washington');
i18nMark('Kansas City');
i18nMark('Cincinnati');
i18nMark('Baltimore');
i18nMark('Denver');
i18nMark('Columbus');
i18nMark('Philadelphia');
i18nMark('New York');
i18nMark('Salt Lake City');
i18nMark('Chicago');
i18nMark('Boston');
i18nMark('Minneapolis');
i18nMark('Portland');
i18nMark('Seattle');
i18nMark('Las Vegas');
