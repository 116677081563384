/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from '@chakra-ui/react';
import useBreakpoint from 'hooks/useBreakpoint';
import { useHeader } from 'hooks/useHeader';
import usePrerender from 'hooks/usePrerender';
import { useScrollPosition } from 'hooks/useScrollPosition';
import React, { lazy, Suspense, useEffect } from 'react';
import { HeaderBackground } from './styled';

const Mobile = lazy(() => import('./mobile'));
const Desktop = lazy(() => import('./desktop'));
const AboutSubNav = lazy(() => import('./AboutSubNav'));
const BlogSubNav = lazy(() => import('./BlogSubNav'));
const ForIndividualSubNav = lazy(() => import('./ForIndividualSubNav'));

interface TProps {
  hideShadow?: boolean;
  onScrolled?: () => void;
}

const Header: React.FC<TProps> = ({ hideShadow, onScrolled }) => {
  const { scroll80 } = useScrollPosition();
  const { transparentHeader, bgColor } = useHeader();
  const { isPrerender } = usePrerender();
  const { isLgScreen } = useBreakpoint();

  useEffect(() => {
    if (onScrolled) onScrolled();
  }, [scroll80]);

  return (
    <Box>
      <HeaderBackground
        className="header-background"
        scrolled={isLgScreen ? scroll80 : false}
        shadow={!hideShadow}
        transparent={transparentHeader}
        bgColor={bgColor}
      >
        <Suspense fallback={<></>}>
          {!isLgScreen ? <Mobile /> : <Desktop />}
          {isPrerender && (
            <Box display="none">
              <ForIndividualSubNav />
              <BlogSubNav />
              <AboutSubNav />
            </Box>
          )}
        </Suspense>
      </HeaderBackground>
    </Box>
  );
};

export default Header;
