export const APP_DEFAULT_DEEPLINK = 'https://insig.ht/app';

export const getSingleTrackDeepLink = (id: string) => {
  return `insight://libraries/${id}`;
};

export const getPremiumAudioTrackDeepLink = (id: string) => {
  return `insight://libraries/${id}?is_premium=true`;
};

export const getCourseDeepLink = (id: string) => {
  return `insight://courses/${id}`;
};

export const getPlaylistDeepLink = (id: string, ownerId: string) => {
  return `insight://playlists/${id}?owner=${ownerId}`;
};

export const getPublisherDeepLink = (id: string) => {
  return `insight://publishers/teacher/${id}`;
};

export const getGroupDeepLink = (groupId: string) => {
  return `insight://groups-v2/${groupId}`;
};

export interface WorkshopWebAndDeepLinkProps {
  ownerId: string;
  shopProductId: string;
  userName: string;
}

export const getWorkshopWebAndDeeplink = ({
  ownerId,
  shopProductId,
  userName
}: WorkshopWebAndDeepLinkProps) => {
  return {
    webLink: `/${userName}/workshops/${shopProductId}`,
    deepLink: `insight://shop-products/${shopProductId}?publisher_id=${ownerId}`
  };
};

export const getCoursesDeepLink = () => {
  return 'insight://discovercourses/explore';
};

export const getWalmartDeepLink = () => {
  return 'insight://walmart';
};

export const getTopicDeepLink = (topicId: string) => {
  return `insight://discoverlibraries/topics/${topicId}`;
};
