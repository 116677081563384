import { AxiosError } from 'axios';

const getApiError = (error: AxiosError) => {
  if (typeof error === 'string') {
    return error;
  }

  return error?.response?.data?.message ?? error?.message ?? null;
};

export default getApiError;
