import { ComponentStyleConfig } from '@chakra-ui/react';

const Text: ComponentStyleConfig = {
  variants: {
    c1: {
      fontSize: '11px',
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    p1: {
      fontSize: '17px',
      lineHeight: '23px'
    },
    'p1.regular': {
      fontSize: '16px',
      lineHeight: '23px',
      fontWeight: 400
    },
    'p1.medium': {
      fontSize: '16px',
      lineHeight: '23px',
      fontWeight: 500
    },
    p2: {
      fontSize: '14px',
      lineHeight: '19px'
    },
    'p2.regular': {
      fontSize: '14px',
      lineHeight: '19px',
      fontWeight: 400
    },
    p3: {
      fontSize: '12px',
      lineHeight: '16px'
    },
    'p3.regular': {
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 400
    }
  },
  defaultProps: {
    variant: 'p1'
  }
};

export default Text;
