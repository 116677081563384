import { ComponentStyleConfig } from '@chakra-ui/react';
import colors from '../foundations/colors';

const tealButton = {
  bg: colors.customTeal,
  _hover: {
    bg: colors.teal[700]
  },
  _focus: {
    bg: colors.teal[700]
  },
  _active: {
    bg: colors.teal[700]
  }
};

const Button: ComponentStyleConfig = {
  baseStyle: {
    colorScheme: 'teal',
    color: 'white',
    fontWeight: 600,
    fontFamily: 'Brandon',
    borderRadius: 8,
    outline: 'none !important'
  },
  sizes: {
    sm: {
      py: '4px',
      px: '4px'
    },
    md: {
      py: '6px',
      px: '8px',
      fontSize: '18px',
      fontWeight: 400
    },
    lg: {
      py: '8px',
      px: '12px'
    }
  },
  variants: {
    secondary: {
      bg: '#ECF2F7',
      color: 'black',
      _hover: {
        bg: '#D5DFE7'
      },
      _focus: {
        bg: '#D5DFE7'
      },
      _active: {
        bg: '#D5DFE7'
      }
    },
    round: {
      ...tealButton,
      rounded: 'full'
    },
    'round-secondary': {
      rounded: 'full',
      bg: '#ECF2F7',
      color: 'black',
      _hover: {
        bg: '#D5DFE7'
      },
      _focus: {
        bg: '#D5DFE7'
      },
      _active: {
        bg: '#D5DFE7'
      }
    },
    transparent: {
      bg: 'none',
      color: 'black',
      _hover: {
        bg: 'none'
      },
      _focus: {
        bg: 'none',
        outline: 'none'
      },
      _active: {
        bg: 'none',
        outline: 'none'
      }
    }
  },
  defaultProps: {
    size: 'md',
    variant: 'solid'
  }
};

export default Button;
