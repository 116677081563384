import getScrollBarWidth from './getScrollbarWidth';

/**
 * Use to set '--scrollbar-width' custom variable which we can use to left some space at the right side of window.
 */
const setCssScrollBarWidthCustomVar = () => {
  const scrollbarWidth = getScrollBarWidth();

  const rootElement = document.documentElement;

  if (!rootElement) {
    return;
  }

  rootElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
};

export default setCssScrollBarWidthCustomVar;
