import { Flex, FlexProps, Spinner } from '@chakra-ui/react';
import React from 'react';

const FullpageSpinner = (props: FlexProps) => {
  return (
    <Flex
      width="100vw"
      height="calc(100vh - 96px)"
      align="center"
      justify="center"
      {...props}
    >
      <Spinner mb="96px" />
    </Flex>
  );
};

export default FullpageSpinner;
