import { GrowthBookProvider as GrowthBookDefaultProvider } from '@growthbook/growthbook-react';
import FullpageSpinner from 'components/FullpageSpinner';
import { useFirebaseAuth } from 'hooks/useFirebaseAuth';
import usePrerender from 'hooks/usePrerender';
import React, { useEffect } from 'react';
import { browserName, fullBrowserVersion } from 'react-device-detect';
import getCountryByTimeZone from 'services/utils/country/helpers';
import growthbook from '.';

/* 
suggestion: we could use LoadingOverlay component here to show a loading when children page is rendering,
but we not using it yet because we are not sure if it's not give bad impact to user experience.
*/

const GrowthBookProvider: React.FC = ({ children }) => {
  const { user, userPseudoId, isSubscriber, isLoading } = useFirebaseAuth();
  const { isPrerender } = usePrerender();

  useEffect(() => {
    if (!isLoading && growthbook) {
      growthbook.setAttributes({
        web_user_pseudo_id: userPseudoId,
        user_id: user?.uid,
        is_subscriber: isSubscriber,
        platform: 'WEB',
        country: getCountryByTimeZone(
          Intl.DateTimeFormat().resolvedOptions().timeZone
        ),
        browser: `${browserName} v${fullBrowserVersion}`
      });

      growthbook.loadFeatures();
    }
  }, [isLoading, isSubscriber, user, userPseudoId]);

  if (isLoading && !isPrerender) return <FullpageSpinner width="100%" />;

  return (
    <GrowthBookDefaultProvider growthbook={growthbook}>
      {children}
      {/* {isLoading && !isPrerender && <LoadingOverlay />} */}
    </GrowthBookDefaultProvider>
  );
};

export default GrowthBookProvider;
