/* eslint-disable react-hooks/exhaustive-deps */
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { HeaderBackground } from 'components/Header/styled';
import { useHeader } from 'hooks/useHeader';
import { useScrollPosition } from 'hooks/useScrollPosition';
import React, { lazy, Suspense, useEffect } from 'react';

const Mobile = lazy(() => import('./mobile'));
const Desktop = lazy(() => import('./desktop'));

interface TProps {
  hideShadow?: boolean;
  onScrolled?: () => void;
}

const Header: React.FC<TProps> = ({ hideShadow, onScrolled }) => {
  const { scroll80 } = useScrollPosition();
  const { bgColor } = useHeader();
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), {
    noSsr: true
  });

  useEffect(() => {
    if (onScrolled) onScrolled();
  }, [scroll80]);

  return (
    <HeaderBackground
      className="header-background"
      scrolled={scroll80}
      shadow={!hideShadow}
      bgColor={bgColor}
    >
      <Suspense fallback={<></>}>{mobile ? <Mobile /> : <Desktop />}</Suspense>
    </HeaderBackground>
  );
};

export default Header;
