export type EventOptions = {
  [key: string]: unknown;
};

const fireEvent = (eventName: string, config?: EventOptions) => {
  const eventOption = config ?? {};
  const event = new CustomEvent(eventName, eventOption);

  if (!window?.dispatchEvent) {
    return;
  }

  window.dispatchEvent(event);
};

export default fireEvent;
