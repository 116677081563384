import { useGrowthBook } from '@growthbook/growthbook-react';
import { useFirebaseAuth } from 'hooks/useFirebaseAuth';
import noop from 'lodash/noop';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as Branch from '../../api/branch';
import { getBranchIOShareUrl } from '../../api/branch';
import { APP_DEFAULT_DEEPLINK } from './helpers';

const growthBookExperimentId = 'website_hero_image';
const growthBookExperimentLink =
  'https://app.growthbook.io/experiment/exp_19g61nlrpqrev2';

const doNotInitOnThisRoutes = [
  '/',
  '/legal/privacy-policy',
  '/legal/california-notice-at-collection'
];

interface Context {
  branchInitialized: boolean;
  getBranchQRUrl: (cb: (link: string) => void) => void;
  generateBranchUrl: ({ cb, data }: Branch.GetBranchIOShareUrlParams) => void;
}

const BranchContext = createContext<Context>({
  branchInitialized: false,
  getBranchQRUrl: noop,
  generateBranchUrl: noop
});

export const BranchProvider: React.FC = ({ children }) => {
  const [branchInitialized, setBranchInitialized] = useState(false);
  const [QRCodeUrl, setQRCodeUrl] = useState('');
  const { pathname } = useLocation();
  const { userPseudoId, user } = useFirebaseAuth();
  const growthbook = useGrowthBook();

  const initBranch = () => {
    setBranchInitialized(true);
    Branch.init(branchData => {
      Branch.saveUTMs(window.location, branchData);
    });
  };

  useEffect(() => {
    if (doNotInitOnThisRoutes.indexOf(pathname) === -1 && !branchInitialized) {
      setBranchInitialized(true);
      initBranch();
    }
  }, [branchInitialized, pathname]);

  const generateBranchUrl = ({
    cb,
    data
  }: Branch.GetBranchIOShareUrlParams) => {
    if (!branchInitialized) {
      initBranch();
    }

    getBranchIOShareUrl({
      cb,
      data: {
        sharerUserId: user?.uid,
        sharerUserPseudoId: userPseudoId,
        linkType: 'web_install_app',
        ...data
      }
    });
  };

  const getBranchQRUrl = (cb: (link: string) => void) => {
    if (!branchInitialized) {
      initBranch();
    }

    if (QRCodeUrl) {
      cb(QRCodeUrl);
      return;
    }

    getBranchIOShareUrl({
      cb: ({ link }) => {
        setQRCodeUrl(link ?? APP_DEFAULT_DEEPLINK);
        return cb(link ?? APP_DEFAULT_DEEPLINK);
      },
      data: {
        sharerUserId: user?.uid,
        sharerUserPseudoId: userPseudoId,
        deepLink: APP_DEFAULT_DEEPLINK,
        experimentId: growthBookExperimentId,
        variantId: growthbook?.getFeatureValue(
          growthBookExperimentId,
          'control'
        ),
        experimentLink: growthBookExperimentLink,
        linkType: 'web_install_app'
      }
    });
  };

  return (
    <BranchContext.Provider
      value={{
        branchInitialized,
        getBranchQRUrl,
        generateBranchUrl
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};

export const useBranch = () => {
  const context = useContext(BranchContext);

  if (!context) {
    throw new Error('useBranch needs to be used within BranchProvider');
  }

  return context;
};
