let elementScrollbarWidth: number | undefined;

/**
 * Use to get scrollbar width used by the browser.
 */
const getScrollBarWidth = () => {
  if (typeof elementScrollbarWidth === 'number') {
    return elementScrollbarWidth;
  }

  const scrollableElement = document.createElement('div');

  scrollableElement.style.visibility = 'hidden';
  scrollableElement.style.overflow = 'scroll';
  document.body.appendChild(scrollableElement);

  elementScrollbarWidth =
    scrollableElement.offsetWidth - scrollableElement.clientWidth;

  document.body.removeChild(scrollableElement);

  return elementScrollbarWidth;
};

export default getScrollBarWidth;
