/* eslint-disable no-console  */
import branch, { BranchError, DeepLinkData, SessionData } from 'branch-sdk';
import dayjs from 'dayjs';
import fireEvent from 'helpers/fireEvent';
import isPrerenderCrawler from 'helpers/isPrerenderCrawler';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { BranchParsedDataSchema } from './types';

let initialized = false;

type TBranchDataCallback = (data: SessionData | null) => void;

/**
 * Used to store branch data of url in browser storage. So that we can access it later.
 * storage key is url display address bar and value is branch data.
 * @param data - Branch data from the url.
 * */
const setBranchDataFromUrl = (data: SessionData) => {
  const link = window.location.href;

  if (!window.sessionStorage) {
    return;
  }

  if (data) {
    window.sessionStorage.setItem(link, JSON.stringify(data));
  } else {
    window.sessionStorage.removeItem(link);
  }

  fireEvent('storage-change', { detail: { link } });
};

/**
 * Return branch link(branch url available in url of browser) data from browser storage.
 * storage key is url display address bar.
 * */
export const getBranchLinkDataFromStorage = () => {
  const link = window.location.href;

  if (!window.sessionStorage) {
    return null;
  }

  const branchData = window.sessionStorage.getItem(link);
  const branchDataFromLink: SessionData | null = branchData
    ? JSON.parse(branchData)
    : null;
  const parsedData: BranchParsedDataSchema | null =
    branchDataFromLink?.data_parsed ?? null;

  return { branchDataFromLink, parsedData };
};

export function init(cb: TBranchDataCallback) {
  initialized = true;

  if (isPrerenderCrawler()) {
    return console.warn('branch.init: PRERENDER CRAWLER');
  }

  if (!process.env.REACT_APP_BRANCH_API_KEY) {
    return console.warn('branch.init: INVALID KEY');
  }

  return branch.init(
    process.env.REACT_APP_BRANCH_API_KEY,
    { tracking_disabled: true },
    (error, branchData) => {
      if (error) {
        console.warn('branch.init: ERROR', error);
      }

      if (branchData) {
        setBranchDataFromUrl(branchData);
      }

      cb(branchData);
    }
  );
}

export function link(
  linkData: branch.DeepLinkData,
  cb: (error: branch.BranchError, link: branch.BranchError) => void
) {
  if (!initialized) {
    init(() => {
      branch.link(linkData, cb);
    });
  } else {
    branch.link(linkData, cb);
  }
}

export function data(cb: TBranchDataCallback) {
  return branch.data((error, branchData) => {
    if (error != null) {
      console.warn('branch.data: ERROR', error);
    }
    cb(branchData);
  });
}

export function setBranchViewData(data: DeepLinkData['data']) {
  try {
    if (data && !isEmpty(data)) {
      console.info(`branch.setBranchViewData: ${Object.keys(data)}`);
      branch.setBranchViewData({ data });
    }
  } catch (err) {
    console.warn('branch.setBranchViewData: ERROR', err);
  }
}

export function trackPageView(
  metadata?: Record<string, unknown>,
  callback?: (err: BranchError) => void
) {
  try {
    if (branch && !isPrerenderCrawler()) {
      branch.track('pageview', metadata, callback);
    }
  } catch (err) {
    console.warn('branch.trackPageView: ERROR', err);
  }
}

export const saveUTMs = (location: Location, data: SessionData | null) => {
  const data_parsed: Record<string, unknown> | undefined = data?.data_parsed;
  const queries = queryString.parse(location.search);
  const utmData: Record<string, unknown> = {};

  if (queries) {
    // save utms from query parameters
    if (queries.utm_source) utmData.utm_source = queries.utm_source;
    if (queries.utm_medium) utmData.utm_medium = queries.utm_medium;
    if (queries.utm_campaign) utmData.utm_campaign = queries.utm_campaign;
    if (queries.utm_content) utmData.utm_content = queries.utm_content;
    if (queries.utm_term) utmData.utm_term = queries.utm_term;
  }

  // only store relevant utm values (if present)
  if (data_parsed && !isEmpty(data_parsed)) {
    // save utms from branch
    if (data_parsed['~campaign'])
      utmData.utm_campaign = data_parsed['~campaign'];
    if (data_parsed['~channel']) utmData.utm_source = data_parsed['~channel'];
    if (data_parsed['~feature']) utmData.utm_medium = data_parsed['~feature'];

    if (data_parsed.$sharer_user_id)
      utmData.$sharer_user_id = data_parsed.$sharer_user_id;
    if (data_parsed.$share_link_id)
      utmData.$share_link_id = data_parsed.$share_link_id;
    if (data_parsed.$share_created_date)
      utmData.$share_created_date = data_parsed.$share_created_date;
  }

  if (!isEmpty(utmData)) {
    const utmDataJson = JSON.stringify(utmData);
    // only set the data if there is any present
    localStorage.setItem('IT-branchAttributionData', utmDataJson);
    console.info('branch.saveUTM', 'IT-branchAttributionData', utmDataJson);
  }
};

export interface BranchData extends UTM_PARAMETERS_SCHEMA {
  $sharer_user_id?: string;
  $share_link_id?: string;
  $share_created_date?: string;
  $deeplink: string;
  $deeplink_v2: string;
  $desktop_url?: string;
  $fallback_url?: string;
}

export interface UTM_PARAMETERS_SCHEMA {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

const utmKeys = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term'
] as const;

/**
 * Get utm parameters(utmKeys) from url.
 * */
export const getUTMParametersFromUrl = (searchStr?: string) => {
  const urlSearchParams = new URLSearchParams(
    searchStr ?? window.location.search
  );
  const utmParams: UTM_PARAMETERS_SCHEMA = {};

  utmKeys.forEach(key => {
    const utmValue = urlSearchParams.get(key);

    if (utmValue) {
      utmParams[key] = utmValue;
    }
  });

  return utmParams;
};

/**
 * Get utm parameters(utmKeys) from local storage and return only available utm parameters.
 * */
export const getUTMParameters = () => {
  const branchAttributesStr = localStorage.getItem('IT-branchAttributionData');
  const branchParameters: Record<string, string> = branchAttributesStr
    ? JSON.parse(branchAttributesStr)
    : {};
  const utmParameters: Record<string, string> = {};

  utmKeys.forEach(key => {
    const utmValue = branchParameters[key];

    if (utmValue !== undefined && utmValue !== null) {
      utmParameters[key] = utmValue;
    }
  });

  return utmParameters as UTM_PARAMETERS_SCHEMA;
};

/**
 * clear local storage timestamps so we can reshow the journey on the same url types
 */
export const clearLocalStorageDismissal = () => {
  try {
    const savedBranchData = localStorage.getItem(
      'BRANCH_WEBSDK_KEYjourneyDismissals'
    );

    const dismissals = savedBranchData && JSON.parse(savedBranchData);

    if (dismissals && dismissals['5ce789a947e87c3451981d86']) {
      delete dismissals['5ce789a947e87c3451981d86'];
      localStorage.setItem(
        'BRANCH_WEBSDK_KEYjourneyDismissals',
        JSON.stringify(dismissals)
      );
    } else if (dismissals && dismissals['5cdcfface9007205bbcbec16']) {
      delete dismissals['5cdcfface9007205bbcbec16'];
      localStorage.setItem(
        'BRANCH_WEBSDK_KEYjourneyDismissals',
        JSON.stringify(dismissals)
      );
    }
  } catch (error) {
    console.warn(error);
  }
};

const searchEngines = [
  '360.cn',
  'alice.com',
  'aliceadsl.fr',
  'alltheweb.com',
  'altavista.com',
  'aol.com',
  'ask.com',
  'search.aol.fr',
  'alicesuche.aol.de',
  'search.auone.jp',
  'isearch.avg.com',
  'search.babylon.com',
  'baidu.com',
  'biglobe.ne.jp',
  'bing.com',
  'search.centrum.cz',
  'search.comcast.net',
  'search.conduit.com',
  'daum.net',
  'ekolay.net',
  'eniro.se',
  'go.mail.ru',
  'goo.ne.jp',
  'search.incredimail.com',
  'kvasir.no',
  'bing.com',
  'lycos.com',
  'search.lycos.de',
  'mamma.com',
  'msn.com',
  'money.msn.com',
  'local.msn.com',
  'mynet.com',
  'najdi.si',
  'naver.com',
  'search.netscape.com',
  'szukaj.onet.pl',
  'ozu.es',
  'rakuten.co.jp',
  'rambler.ru',
  'search-results.com',
  'search.smt.docomo.ne.jp',
  'sesam.no',
  'seznam.cz',
  'sogou.com',
  'szukacz.pl',
  'buscador.terra.com.br',
  'search.tut.by',
  'search.ukr.net',
  'search.virgilio.it',
  'voila.fr',
  'wp.pl',
  'yahoo.com',
  'yahoo.cn',
  'yahoo.com',
  'google.com',
  'yandex.com',
  'yandex.ru',
  'yam.com'
];

const getReferrer = () => {
  const a = document.createElement('a');
  a.href = document.referrer;

  let domain = a.hostname;
  let medium = 'referral';

  if (domain.startsWith('m.')) {
    domain = domain.replace(/^m\./, '');
  } else {
    domain = domain.replace(/^www\./, '');
  }

  if (domain.startsWith('insighttimer.com')) {
    domain = '(direct)';
    medium = '(none)';
  } else if (domain.startsWith('google.')) {
    domain = 'google';
    medium = 'organic';
  } else if (domain.startsWith('yahoo.')) {
    domain = 'yahoo';
    medium = 'organic';
  } else if (domain.startsWith('bing.')) {
    domain = 'bing';
    medium = 'organic';
  } else if (domain.startsWith('duckduckgo.')) {
    domain = 'duckduckgo';
    medium = 'organic';
  } else if (searchEngines.includes(domain)) {
    medium = 'organic';
  }

  return {
    domain,
    medium
  };
};

export const analyticsQuery = (tag: string) => {
  const savedBranchData = localStorage.getItem('IT-branchAttributionData');

  const branchData = savedBranchData && JSON.parse(savedBranchData);

  const data: Record<string, string | number> = {};
  const referrer = getReferrer();

  data['~channel'] = (branchData && branchData.utm_source) || referrer.domain;
  data['~feature'] = (branchData && branchData.utm_medium) || referrer.medium;

  if (branchData && branchData.utm_campaign)
    data['~campaign'] = branchData.utm_campaign;

  if (branchData && branchData.utm_content)
    data['~keyword'] = branchData.utm_content;

  if (branchData && branchData.utm_term) data.tags = branchData.utm_term;

  // add ab test tag
  const abTestBranchTag = sessionStorage.getItem('abtest_branch_tag');

  return (
    queryString.stringify(data) +
    (tag ? `&tags=${tag}` : '') +
    (abTestBranchTag ? `&tags=${abTestBranchTag}` : '')
  );
};

export const branchCloseJourneyPopup = (successCallback?: () => void) => {
  branch.closeJourney((err: BranchError) => {
    if (err) {
      console.error('Journey popup close error:: ', err);
      return;
    }

    if (successCallback) {
      successCallback();
    }
  });
};

export type BranchCampaign = 'web-share' | 'walmartplus';

export interface BranchSharedUrlDataSchema {
  // branch data that you want to set in generated link
  webLink?: string;
  deepLink: string;
  fallbackUrl?: string;
  channel?: BranchCampaign | string; // utm_source
  campaign?: string; // utm_campaign
  sharerUserId?: string;
  sharerUserPseudoId?: string | null;
  utmMedium?: string; // utm_medium
  utmContent?: string; // utm_content
  utmTerm?: string; // utm_term
  isWebOnly?: boolean; // set to true if the link is only for web.
  additionalBranchData?: DeepLinkData['data']; // additional branch data like og details or if there is need to override branch data
  twitterCard?: string;
  experimentId?: string;
  experimentLink?: string;
  variantId?: string;
  linkType?: 'web_install_app' | 'content_share';
  shareLinkId?: string;
  onboardingAnswers?: {
    reason: string;
    experience: string;
    duration: string;
  };
}

export interface BranchCallbackParams {
  link?: string | null;
  error?: string | null;
  branchLinkDetails: DeepLinkData;
}

export interface GetBranchIOShareUrlParams {
  cb: (params: BranchCallbackParams) => void;
  data: BranchSharedUrlDataSchema;
}

/**
 * Used to get branch link based on deeplink, weblink, sharer user id, fallback url, etc.
 */
export const getBranchIOShareUrl = ({
  cb,
  data
}: GetBranchIOShareUrlParams) => {
  const {
    deepLink,
    webLink,
    fallbackUrl,
    channel, // utm_source
    campaign = 'web-share', // utm_campaign
    sharerUserId,
    sharerUserPseudoId,
    utmMedium, // utm_medium
    utmContent, // utm_content
    utmTerm, // utm_term,
    twitterCard,
    isWebOnly,
    additionalBranchData = {},
    experimentId,
    experimentLink,
    variantId,
    linkType,
    shareLinkId
  } = data;
  const utmParameterFromUrl = getUTMParametersFromUrl();

  const utmFields: UTM_PARAMETERS_SCHEMA = {
    utm_source: channel,
    utm_campaign: campaign,
    utm_medium: utmMedium,
    utm_content: utmContent,
    utm_term: utmTerm
  };

  let formattedData: DeepLinkData['data'] = {
    $deeplink: deepLink,
    $deeplink_v2: deepLink,
    $sharer_user_id: sharerUserId,
    $sharer_user_pseudo_id: sharerUserPseudoId,
    $share_link_id: shareLinkId ?? uuidv4(),
    $share_created_date: dayjs()
      .valueOf()
      .toString(),
    $desktop_url: webLink,
    $fallback_url: fallbackUrl,
    $ios_passive_deepview: 'false',
    $android_passive_deepview: 'false',
    $web_only: !!isWebOnly,
    '~keyword':
      utmContent && utmContent.length ? utmContent.split(',') : undefined,
    ...utmFields,
    ...utmParameterFromUrl,
    ...(twitterCard ? { $twitter_card: twitterCard } : {}),
    $experiment_id: experimentId,
    $experiment_link: experimentLink,
    $variant_id: variantId,
    $link_type: linkType,
    $ipad_url: 'https://insig.ht/app',
    ...additionalBranchData
  };

  formattedData = pickBy(
    formattedData,
    value => value !== undefined && value !== null
  );

  let branchDeepLinkDetails: DeepLinkData = {
    channel,
    campaign,
    feature: utmMedium,
    tags: utmTerm && utmTerm.length ? utmTerm.split(',') : undefined,
    data: formattedData
  };

  branchDeepLinkDetails = pickBy(
    branchDeepLinkDetails,
    value => value !== undefined && value !== null
  );

  branch.link(cloneDeep(branchDeepLinkDetails), (error, link) => {
    const finalLink = error ? null : link;
    cb({ error, link: finalLink, branchLinkDetails: branchDeepLinkDetails });
  });
};

export const getBranchLinkDetailsFromLocalStore = () => {
  const branchAttributesStr: BranchData = JSON.parse(
    localStorage.getItem('IT-branchAttributionData') ?? '{}'
  );

  return branchAttributesStr;
};
