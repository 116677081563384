//  google recommended events:
// https://support.google.com/firebase/answer/9267735?hl=en&ref_topic=6317484
enum GA_EVENTS {
  CLICK = 'click',
  PAGE_VIEW = 'page_view',
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  PURCHASE = 'purchase',
  VIEW = 'view',
  EXPERIMENT_ACTIVATED = 'experiment_activated',
  SERVICE_EVENT = 'service_event',
  SWIPE = 'swipe',
  IMPRESSION = 'impression'
}

export enum GA_IMPRESSION_EVENT_NAMES {
  SECTION_IMPRESSION = 'section_impression',
  RETREAT_IMPRESSION = 'retreat_impression'
}

export enum GA_CLICK_EVENT_NAMES {
  CHECKOUT_CLICKED = 'checkout_button_clicked',
  BROWSE_COURSE_CLICKED = 'browse_course_clicked',
  CHALLENGE_BANNER_CLICKED = 'challenge_banner_clicked',
  CONTACT_US_CLICKED = 'contact_us_clicked',

  DOWNLOAD_APP_IOS_CLICKED = 'download_app_ios_clicked',
  DOWNLOAD_APP_ANDROID_CLICKED = 'download_app_android_clicked',
  DOWNLOAD_APP_LISTEN_OFFLINE_CLICKED = 'download_app_listen_offline_clicked',
  DOWNLOAD_APP_PLAY_IN_APP_CLICKED = 'download_app_play_in_app_clicked',
  SHARE_BUTTON_CLICKED = 'share_button_clicked',
  PLAY_CLICKED = 'play_clicked',

  // Auth
  CONTINUE_WITH_GOOGLE_CLICKED = 'continue_with_google_clicked',
  CONTINUE_WITH_APPLE_CLICKED = 'continue_with_apple_clicked',
  CONTINUE_WITH_FACEBOOK_CLICKED = 'continue_with_facebook_clicked',
  SIGN_UP_WITH_EMAIL_CLICKED = 'sign_up_with_email_clicked',
  SIGN_UP_DISMISSED = 'sign_up_dismissed',
  LOG_IN_CLICKED = 'log_in_clicked',
  SIGN_UP_CLICKED = 'sign_up_clicked',

  // Course
  PLAY_LESSON_CLICKED = 'play_lesson_clicked',

  // Download App
  DOWNLOAD_APP_CLICKED = 'download_app_clicked', // download app button clicked on desktop
  DOWNLOAD_FREE_APP_CLICKED = 'download_free_app_clicked',

  // For companies:
  START_NOW_ITS_FREE_CLICKED = 'start_now_its_free_clicked',
  VIEW_PLANS_CLICKED = 'view_plans_clicked',
  GET_STARTED_FOR_FREE_CLICKED = 'get_started_for_free_clicked',

  // Redeem code
  REDEEM_CODE_CLICKED = 'redeem_code_clicked',

  // Onboarding Quiz
  ONBOARDING_OPTION_CLICKED = 'onboarding_option_clicked',
  ONBOARDING_DOWNLOAD_APP_CLICKED = 'onboarding_download_app_clicked',
  ONBOARDING_BACK_CLICKED = 'onboarding_back_clicked',
  ONBOARDING_CLOSE_MODAL_CLICKED = 'onboarding_close_modal_clicked',

  // Welness
  WELLNESS_DOWNLOAD_PDF_CLICKED = 'download_pdf_wellness_expert_clicked',

  // Retreats
  PHOTO_CLICKED = 'photo_clicked',
  SHOW_ALL_PHOTOS_CLICKED = 'show_all_photos_clicked',
  BOOK_NOW_CLICKED = 'book_now_clicked',
  SHOW_MORE_CLICKED = 'show_more_clicked',
  MAP_CLICKED = 'map_clicked',
  REVIEW_COUNT_CLICKED = 'review_count_clicked',
  SHOW_ALL_AMENITIES_CLICKED = 'show_all_amenities_clicked',
  CARAOUSEL_SWIPED = 'carousel_swiped',
  CARAOUSEL_NEXT_CLICKED = 'carousel_next_clicked',
  CARAOUSEL_PREV_CLICKED = 'carousel_prev_clicked',
  ACCOMODATION_OPTION_CLICKED = 'accommodation_option_clicked',
  COLLAPSABLE_LIST_ITEM_CLICKED = 'collapsable_list_item_clicked',
  SORT_ORDER_CLICKED = 'sort_order_clicked',
  SORT_OPTION_CLICKED = 'sort_option_clicked',
  REMOVE_FILTER_PILL_CLICKED = 'remove_filter_pill_clicked',
  SIDEBAR_FILTER_OPTION_CLICKED = 'sidebar_filter_option_clicked',
  SIDEBAR_FILTER_SHOW_MORE_CLICKED = 'sidebar_filter_show_more_clicked',
  RETREAT_TILE_CLICKED = 'retreat_tile_clicked',
  SEARCH_BAR_FILTER_CLICKED = 'search_bar_filter_clicked',
  SEARCH_BAR_FILTER_OPTION_CLICKED = 'search_bar_filter_option_clicked',
  SEARCH_BAR_FILTER_SHOW_MORE_CLICKED = 'search_bar_filter_show_more_clicked',
  SEARCH_BAR_DATE_PICKER_NEXT_CLICKED = 'search_bar_date_picker_next_clicked',
  SEARCH_BAR_DATE_PICKER_PREV_CLICKED = 'search_bar_date_picker_prev_clicked',
  OPTION_SELECT_CLICKED = 'select_clicked',
  OPTION_PHOTO_CLICKED = 'photo_clicked',
  BACK_CLICKED = 'back_clicked',
  PAY_NOW_CLICKED = 'pay_now_clicked'
}

export enum ANALYTICS_SERVICE_EVENT_NAMES {
  SHARE_COMPLETED = 'share_completed',
  BRANCH_APP_STORE_REDIRECT = 'branch_app_store_redirect',
  USER_REGISTRATION_COMPLETED = 'user_registration_completed'
}

export default GA_EVENTS;
