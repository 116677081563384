import { Global } from '@emotion/react';
import React from 'react';

const OsanoStyle = () => {
  return (
    <Global
      styles={{
        '.osano-cm-widget': { display: 'none' }
      }}
    />
  );
};

export default OsanoStyle;
