import { GrowthBook } from '@growthbook/growthbook-react';
import isPrerenderCrawler from 'helpers/isPrerenderCrawler';
import { isProduction } from 'lib/environment';
import { logGAEvent } from 'lib/firebase/ga_analytics';
import GA_EVENTS from 'lib/firebase/ga_events';

const growthbook = isPrerenderCrawler()
  ? undefined
  : new GrowthBook({
      apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
      clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: !isProduction,
      subscribeToChanges: !isProduction,
      trackingCallback: (experiment, result) => {
        logGAEvent({
          event_name: GA_EVENTS.EXPERIMENT_ACTIVATED,
          experiment_id: experiment.key,
          variation_id: `var_${result.variationId}`
        });
      }
    });

export default growthbook;
