import { Box } from '@chakra-ui/react';
import NotifyLink from 'components/Notification/NotifyLink';
import NotifyMessage from 'components/Notification/NotifyMessage';
import { HeaderProvider } from 'hooks/useHeader';
import { useLinguiI18n } from 'hooks/useLinguiI18n';
import { BaseLocales } from 'locales/i18nLingui';
import React, { Suspense } from 'react';
import { isMobile } from 'react-device-detect';
import { Switch } from 'react-router';

const HeaderLayout: React.FC = ({ children }) => {
  const i18n = useLinguiI18n();

  return (
    <HeaderProvider>
      {process.env.REACT_APP_ENABLE_NOTIFICATION === 'true' && (
        <NotifyMessage
          id={
            i18n.language === BaseLocales.English
              ? 'browse_live_events_notification'
              : undefined
          }
        >
          <Box component="span" color="rgb(255, 255, 255, 0.9)">
            {isMobile
              ? "Connect and engage with the world's best teachers, Live, every hour of the day for free."
              : "Connect with the world's best teachers, Live, every hour of the day for free."}
          </Box>
          <NotifyLink to="/live">
            {isMobile ? 'Browse' : 'Browse Live events'}
          </NotifyLink>
        </NotifyMessage>
      )}
      <Suspense fallback={<></>}>
        <Switch>{children}</Switch>
      </Suspense>
    </HeaderProvider>
  );
};

export default HeaderLayout;
