import React, { createContext, FunctionComponent, useContext } from 'react';
import { getGlobalStats, GlobalStats } from 'services/admin';
import getApiError from 'services/getApiError';
import useSWR from 'swr';

export const GlobalContext = createContext<
  GlobalStats & { isGlobalStatsLoading?: boolean; errMessage?: string }
>({});

export const GlobalStatsProvider: FunctionComponent = ({ children }) => {
  const { data, isValidating, error } = useSWR(
    'insightimer/global_state',
    () => {
      return getGlobalStats();
    },
    {
      revalidateOnFocus: false,
      refreshWhenOffline: false
    }
  );
  const globalStats = data ?? {};
  const isGlobalStatsLoading = isValidating && !data;
  const errMessage = getApiError(error);

  return (
    <GlobalContext.Provider
      value={{ ...globalStats, isGlobalStatsLoading, errMessage }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalStats = () => useContext(GlobalContext);
