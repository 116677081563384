import { isProduction } from 'lib/environment';

const deleteCookie = (name: string) => {
  let domain = '';

  if (isProduction) {
    domain = 'Domain=.insighttimer.com;';
  }

  document.cookie = `${name}=; ${domain} Path=/; Expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export default deleteCookie;
