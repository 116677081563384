import Axios from 'axios';

export type GlobalStats = {
  five_star_reviews?: number;
  free_guided_meditations?: number;
  meditators?: number;
  meditators_now?: number;
  meditators_today?: number;
  teachers?: number;
  timestamp?: number;
  activities_locations?: { lat: number; lon: number }[];
  number_of_courses?: number; // rounding count of courses
  courses_all?: number; // Exact count of courses
  number_of_email_domains?: number;
  number_of_groups?: number;
  number_of_live_events?: string;
  number_of_private_groups?: number;
  number_of_public_groups?: number;
  people_this_week?: number;
  total_users?: number;
};

export function getGlobalStats(): Promise<GlobalStats> {
  return Axios.get<GlobalStats>(
    `${process.env.REACT_APP_STATS_HOST}/admin/data/global_stats.json`
  ).then(resp => {
    return resp.data;
  });
}
