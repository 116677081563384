// Material Ui
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component } from 'react';

class MaterialUiProvider extends Component {
  constructor(props) {
    super(props);
    this.theme = createMuiTheme({
      spacing: 10,
      overrides: {
        MuiButton: {
          root: {
            textTransform: 'none'
          }
        },
        MuiIconButton: {
          root: {
            textTransform: 'none'
          }
        },
        MuiToggleButton: {
          root: {
            textTransform: 'none'
          }
        },
        MuiDrawer: {
          root: {
            zIndex: '1001 !important'
          }
        }
      },
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#2b7879'
        },
        background: {
          default: '#fff'
        }
      }
    });
  }

  render() {
    return (
      <MuiThemeProvider theme={this.theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default MaterialUiProvider;
