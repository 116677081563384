import { ComponentStyleConfig } from '@chakra-ui/react';

const CloseButton: ComponentStyleConfig = {
  baseStyle: {
    color: '#636d6d',
    background: '#eff3f3',
    borderRadius: '10px',
    _hover: { backgroundColor: '#E6E8E8' },
    boxShadow: 'none !important',
    // SVG CSS to make the close icon bold
    stroke: '#636d6d',
    strokeWidth: '2px'
  }
};

export default CloseButton;
