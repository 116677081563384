import { createDocRef, getDocument } from 'lib/firebase/utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DateAndTime } from 'services/interface';
import { getUserPrivateSettings, UserSettingDetails } from 'services/user/api';
import useSWR from 'swr';
import { AuthState, useFirebaseAuth } from './useFirebaseAuth';

interface T_UserSubscriptionDetails {
  expiry_date: DateAndTime;
  packs: string[];
  purchase_date: DateAndTime;
  type: string;
  validate_date: DateAndTime;
  is_subscriber_web: boolean;
}

interface GetUserSubscriptionDetailsParams {
  userId: string;
}

export const getUserSubscriptionDetails = ({
  userId
}: GetUserSubscriptionDetailsParams): Promise<T_UserSubscriptionDetails | null> => {
  const subscriptionDocRef = createDocRef(
    `users/${userId}/private/subscription`
  );

  return getDocument(subscriptionDocRef).then(doc => {
    if (!doc.exists) {
      return null;
    }

    const subscriptionDetails = doc.data() as T_UserSubscriptionDetails;

    return subscriptionDetails ?? null;
  });
};

export interface GetUserSubscriptionDetailsKeyParams {
  userId?: string;
}

export const getUserSubscriptionDetailsKey = ({
  userId
}: GetUserSubscriptionDetailsKeyParams) => {
  return userId ? `/users/${userId}/subscriptionDetails` : null;
};

export const useGetUserSubscriptionDetails = ({
  userId
}: {
  userId?: string;
}) => {
  const userSubscriptionKey = getUserSubscriptionDetailsKey({ userId });
  const {
    data: userSubscriptionDetails,
    isValidating,
    error,
    revalidate,
    mutate
  } = useSWR(
    userSubscriptionKey,
    () => {
      if (!userId) {
        return null;
      }

      return getUserSubscriptionDetails({ userId });
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: true,
      shouldRetryOnError: false
    }
  );
  const isSubscriptionDetailsLoading = !!(
    userId &&
    isValidating &&
    !userSubscriptionDetails
  );

  return {
    userSubscriptionDetails,
    isSubscriptionDetailsLoading,
    error,
    revalidate,
    mutate
  };
};
interface UserSettingDoc {
  is_subscriber?: boolean;
}

interface Context {
  isLoading: boolean;
  isSubscriber: boolean;
  setIsSubscriber: (value: boolean) => void;
  isUserWebSubscriptionActive: boolean;
  userSubscriptionDetails?: T_UserSubscriptionDetails | null;
  validateSubscriptionDetails: () => Promise<
    UserSettingDetails | undefined | null
  >;
}

export const UserSubscriberContext = React.createContext<Context | undefined>(
  undefined
);

export const UserSubscriberProvider: React.FC = ({ children }) => {
  const { user, authState } = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscriber, setIsSubscriber] = useState(false);
  const isAuthorized = authState === AuthState.AUTHORIZED;
  const {
    userSubscriptionDetails,
    isSubscriptionDetailsLoading
  } = useGetUserSubscriptionDetails({
    userId: isAuthorized && user?.uid ? user.uid : undefined
  });
  const { is_subscriber_web } = userSubscriptionDetails ?? {};
  const isUserWebSubscriptionActive = !!is_subscriber_web;

  // eslint-disable-next-line consistent-return
  const validateSubscriptionDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const userPrivateSettings = await getUserPrivateSettings();

      setIsSubscriber(!!userPrivateSettings?.is_subscriber);

      return userPrivateSettings;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (authState === AuthState.AUTHORIZED && user) {
      setIsLoading(true);
      // Fetch user settings
      const docRef = createDocRef(`users/${user.uid}/private/settings`);
      getDocument(docRef)
        .then(doc => {
          if (doc.exists()) {
            const data: UserSettingDoc | undefined = doc.data();
            setIsSubscriber(data?.is_subscriber ? data.is_subscriber : false);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (authState === AuthState.UNAUTHORIZED) {
      setIsLoading(false);
      setIsSubscriber(false);
    }
  }, [authState, user]);

  return (
    <UserSubscriberContext.Provider
      value={{
        isLoading: isLoading || isSubscriptionDetailsLoading,
        isSubscriber,
        isUserWebSubscriptionActive,
        userSubscriptionDetails,
        setIsSubscriber,
        validateSubscriptionDetails
      }}
    >
      {children}
    </UserSubscriberContext.Provider>
  );
};

export function useUserSubscriber() {
  const context = useContext(UserSubscriberContext);
  if (!context) {
    throw new Error(
      'useUserSubscriber needs to be used within UserSubscriberProvider'
    );
  }

  return context;
}
