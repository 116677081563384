/* eslint-disable no-bitwise */
/* eslint-disable import/extensions */
// eslint-disable-next-line no-restricted-imports
import { Catalogs, setupI18n } from '@lingui/core';
import messagesEn from 'locales/en/messages.js';
import numeral from 'numeral';
import './i18nMark';
import './i18nMarkCities';

export enum RegionalLocales {
  SpanishSpain = 'es-es',
  FrenchFrance = 'fr-fr',
  FrenchCanada = 'fr-ca',
  GermanGermany = 'de-de',
  GermanSwitzerland = 'de-ch',
  SpanishMexico = 'es-mx',
  EnglishAustralia = 'en-au',
  EnglishCanada = 'en-ca',
  EnglishUK = 'en-gb',
  EnglishIndia = 'en-in',
  Brazilian = 'br'
}

export enum BaseLocales {
  English = 'en',
  BrazilianPortuguese = 'pt-BR',
  Spanish = 'es',
  French = 'fr',
  German = 'de',
  Russian = 'ru',
  PseudoLocale = 'pseudo-LOCALE',
  Dutch = 'nl',
  Italian = 'it'
}

export type Locales = RegionalLocales | BaseLocales;

export const baseLocalesList = Object.values(BaseLocales).filter(
  value => value !== BaseLocales.PseudoLocale
);

export type LinguiCatalogs = Catalogs;

export const getDefaultCatalogs = () => {
  return {
    [BaseLocales.English]: messagesEn
  };
};

export const i18n = setupI18n({
  language: BaseLocales.English,
  catalogs: getDefaultCatalogs(),
  locales: baseLocalesList
});

numeral.register('locale', 'en+', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'million',
    billion: 'b',
    trillion: 't'
  },
  ordinal(number) {
    const b = number % 10;
    return ~~((number % 100) / 10) === 1
      ? 'th'
      : b === 1
      ? 'st'
      : b === 2
      ? 'nd'
      : b === 3
      ? 'rd'
      : 'th';
  },
  currency: {
    symbol: '$'
  }
});

numeral.register('locale', 'pt+', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhoes',
    billion: 'b',
    trillion: 't'
  },
  ordinal(number) {
    const b = number % 10;
    return ~~((number % 100) / 10) === 1
      ? 'th'
      : b === 1
      ? 'st'
      : b === 2
      ? 'nd'
      : b === 3
      ? 'rd'
      : 'th';
  },
  currency: {
    symbol: '$'
  }
});
