import {
  addDoc,
  collection,
  CollectionReference,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getDocs,
  PartialWithFieldValue,
  query,
  Query,
  QueryConstraint,
  runTransaction,
  setDoc,
  SetOptions,
  Transaction,
  WithFieldValue
} from 'firebase/firestore/lite';
import { firestore } from '..';

export function createDocRef(path: string, ...pathSegments: string[]) {
  return doc(firestore, path, ...pathSegments);
}

export function createCollectionRef(path: string, ...pathSegments: string[]) {
  return collection(firestore, path, ...pathSegments);
}

export async function setDocument(
  reference: DocumentReference<DocumentData>,
  data: PartialWithFieldValue<DocumentData>,
  options?: SetOptions
) {
  if (options == null) return setDoc(reference, data);
  return setDoc(reference, data, options);
}

export async function getDocument(reference: DocumentReference<DocumentData>) {
  return getDoc(reference);
}

export async function getDocuments(
  queryRef: Query<DocumentData>,
  ...queryConstraints: QueryConstraint[]
) {
  const q = query(queryRef, ...queryConstraints);
  return getDocs(q);
}

export async function firestoreRunTransaction(
  updateFunction: (transaction: Transaction) => Promise<void>
) {
  return runTransaction(firestore, updateFunction);
}
// create a document. Cloud Firestore auto-generate an ID for you. You can do this by calling addDoc()
export async function addDocument(
  reference: CollectionReference<DocumentData>,
  data: WithFieldValue<DocumentData>
) {
  return addDoc(reference, data);
}
