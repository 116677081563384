import { checkIsPrerender } from 'hooks/usePrerender';

const initClarity = () => {
  if (checkIsPrerender()) {
    return;
  }

  import('clarity-js').then(({ clarity }) => {
    clarity.start({
      projectId: 'jcvas6mgax',
      upload: 'https://m.clarity.ms/collect',
      track: true,
      content: true
    });
  });
};

export default initClarity;
