import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { FC, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  topbanner: {
    padding: '10px 40px 10px 10px',
    zIndex: 1100
  },
  topbannerContent: {
    color: 'rgba(255, 255, 255, 0.9)',
    lineHeight: '20px',
    letterSpacing: '0px',
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px'
    }
  },
  closeButton: {
    marginRight: '26px',
    [theme.breakpoints.down('md')]: {
      marginRight: '16px'
    }
  }
}));

interface TProps {
  onClose: () => void;
}

const TopBanner: FC<TProps> = ({ children, onClose }) => {
  const classes = useStyles();
  const [close, setClose] = useState(false);

  const handleClose = () => {
    setClose(true);
    onClose();
  };

  return (
    <div
      className={`relative border-none shadow-none outline-none focus:border-none bg-black focus:shadow-none focus:outline-none ${
        classes.topbanner
      } ${close && 'hidden'}`}
    >
      <div className="absolute right-0 z-50 top-0 h-full">
        <IconButton
          onClick={handleClose}
          className={`bg-transparent text-white hover:text-white p-1 outline-none h-full ${classes.closeButton}`}
          aria-label="Close notification"
        >
          <CloseIcon className="w-4 h-4" />
        </IconButton>
      </div>
      <span
        className={`w-full inline-block font-ProxiRegular ${classes.topbannerContent} text-center`}
      >
        {children}
      </span>
    </div>
  );
};

export default TopBanner;
