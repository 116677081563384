// eslint disable

import { User } from 'firebase/auth';
import { firebaseAuth, logout as firebaseLogout } from 'lib/firebase/auth';
import { getUserPsuedoId } from 'lib/firebase/ga_analytics';
import { createDocRef, getDocument } from 'lib/firebase/utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

export enum AuthState {
  PENDING,
  UNAUTHORIZED,
  AUTHORIZED
}

export interface FirebaseUser {
  name?: string;
  email?: string;
  is_subscriber?: boolean;
}

export const AuthContext = React.createContext<{
  authState: AuthState;
  user?: User;
  userPseudoId?: string | null;
  userName?: string;
  userEmail?: string;
  isSubscriber?: boolean;
  logOut: () => Promise<void>;
  hideSignupLink?: boolean;
  isLoading: boolean;
  changeHideSignupLinkStatus?: (value: boolean) => void;
}>({
  authState: AuthState.PENDING,
  logOut: () => Promise.resolve(),
  hideSignupLink: false,
  isLoading: true
});

export const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>(AuthState.PENDING);
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>();
  const [user, setUser] = useState<User | null>();
  const [hideSignupLink, setHideSignupLink] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const firebaseAuthMemo = useMemo(() => firebaseAuth, []);

  const { data: userPseudoId, isValidating } = useSWR(
    authState !== AuthState.PENDING ? `user-pseudo-id` : null,
    getUserPsuedoId,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setUser(user);
        setAuthState(AuthState.AUTHORIZED);

        // Fetch user info
        const docRef = createDocRef(`users/${user.uid}/private/settings`);
        getDocument(docRef)
          .then(doc => {
            if (doc.exists()) {
              const data: any = doc.data();

              setFirebaseUser({
                name: data.name ?? user.displayName,
                email: data.email,
                is_subscriber: data.is_subscriber
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setUser(undefined);
        setFirebaseUser(undefined);
        setLoading(false);
        setAuthState(AuthState.UNAUTHORIZED);
      }
    });
  }, [firebaseAuthMemo]);

  async function logOut() {
    return firebaseLogout().catch(err => console.warn(err));
  }

  return (
    <AuthContext.Provider
      value={{
        user: user || undefined,
        isSubscriber: firebaseUser && firebaseUser.is_subscriber,
        userName: firebaseUser && firebaseUser.name,
        userEmail: firebaseUser && firebaseUser.email,
        authState,
        hideSignupLink,
        userPseudoId,
        changeHideSignupLinkStatus: setHideSignupLink,
        logOut,
        isLoading: isLoading || isValidating
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useFirebaseAuth = () => useContext(AuthContext);
