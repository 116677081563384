import usePrerender from 'hooks/usePrerender';
import React, { useEffect } from 'react';
import { LooseObject } from 'services/interface';
import OsanoStyle from './OsanoStyle';

const OsanoScript = () => {
  const { isPrerender } = usePrerender();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const onPageLoad = () => {
      if (window.Osano?.cm) {
        window.Osano.cm.addEventListener(
          'osano-cm-consent-changed',
          (consentChange: LooseObject) => {
            if (consentChange.OPT_OUT === 'ACCEPT') {
              const analyticsElement = document.getElementById(
                'osano-cm-drawer-toggle--category_ANALYTICS'
              ) as HTMLInputElement;
              if (analyticsElement?.checked) {
                analyticsElement.click();
              }
              const personalizationElement = document.getElementById(
                'osano-cm-drawer-toggle--category_PERSONALIZATION'
              ) as HTMLInputElement;
              if (personalizationElement?.checked) {
                personalizationElement.click();
              }
            }
          }
        );
      }
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  if (isPrerender) {
    return null;
  }

  if (window.location.pathname.includes('/legal/')) return null;

  return <OsanoStyle />;
};

export default OsanoScript;
