import { ComponentStyleConfig } from '@chakra-ui/react';

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 700
  },
  variants: {
    h1: {
      fontSize: '32px',
      lineHeight: '35px'
    },
    h2: {
      fontSize: '28px',
      lineHeight: '34px'
    },
    'h2.medium': {
      fontSize: '28px',
      lineHeight: '34px',
      fontWeight: 500
    },
    h3: {
      fontSize: '24px',
      lineHeight: '33px'
    },
    'h3.medium': {
      fontSize: '24px',
      lineHeight: '33px',
      fontWeight: 500
    },
    'h3.600': {
      fontSize: '24px',
      lineHeight: '33px',
      fontWeight: 600
    },
    h4: {
      fontSize: '20px',
      lineHeight: '26px'
    },
    'h4.medium': {
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 500
    },
    subheading: {
      fontSize: '18px',
      lineHeight: '23px'
    },
    'subheading.medium': {
      fontSize: '18px',
      lineHeight: '23px',
      fontWeight: 500
    },
    'subheading.regular': {
      fontSize: '18px',
      lineHeight: '23px',
      fontWeight: 400
    }
  },
  defaultProps: {
    variant: 'h1'
  }
};

export default Heading;
